import React from "react"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import Layout from "../../layouts/MainLayout/Layout"
import Seo from "../../config-components/seo"
import Wrapper from "../../components/Wrapper/Wrapper"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  Catalog,
  MainTitle,
  Product,
  ProductContainer,
} from "./PageCatalog.styled"

const PageCatalog = ({ location, pageContext }) => {
  const query = useStaticQuery(graphql`
    query CatalogQuery {
      photos: allFile(
        filter: { relativeDirectory: { regex: "/images/catalog/previews/" } }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  const getImage = (name) =>
    query.photos.edges
      .filter((el) => el.node.name === name)
      .map((el) => el.node.publicURL)

  const products = [
    {
      title: "Катамаран на \nнадувных баллонах",
      url: "/catalog/marina-m1",
      img: getImage("marina-m1"),
    },
    {
      title: "Модули \nплавучести",
      url: "/catalog/modules",
      img: getImage("modules"),
    },
    {
      title: "Аксессуары и \nзапчасти",
      url: "/catalog/accessories",
      img: getImage("accessories"),
    },
    {
      title: "Катамараны на\n пластиковых баллонах ",
      url: "/catalog/marina-plastic",
      img: getImage("marina-plastic"),
    },
  ]

  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Header page={location.pathname} />

      <Wrapper width={1060}>
        <div
          style={{ width: "100%", padding: "16px", boxSizing: "border-box" }}
        >
          <Breadcrumb
            crumbs={crumbs}
            crumbLabel="Каталог"
            crumbSeparator=" > "
          />
        </div>
        <Seo
          title="Решили купить катамаран? Посмотрите наш каталог."
          description="Катамаран надувной, катамаран педальный, водный батут, модули плавучести - все в нашем каталоге"
          keywords="водный велосипед купить, надувной катамаран купить, купить катамаран педальный, купить водный батут"
        />
        <MainTitle style={{ marginBottom: 7, fontWeight: "bold" }}>
          <h1>Каталог нашей продукции</h1>
        </MainTitle>
        <Catalog>
          {products.map((product) => (
            <ProductContainer key={product.url}>
              <Product>
                <Link
                  to={product.url}
                  style={{
                    textDecoration: "none",
                    color: "#5A6578",
                    fontWeight: "bold",
                    whiteSpace: "pre-line",
                  }}
                >
                  <img
                    key={product.url}
                    src={product.img}
                    alt={product.title}
                    title={product.title}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      height: "auto",
                    }}
                  />
                  {product.title}
                </Link>
              </Product>
            </ProductContainer>
          ))}
        </Catalog>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default PageCatalog
