import styled from "styled-components"
import { Link } from "gatsby"

export const PhotoStyled = styled.img`
  max-width: 754px;
  width: 100%;
  position: relative;
  right: 10px;

  @media screen and (max-width: 400px) {
    right: 5px;
  }
`

export const Text = styled.div`
  line-height: 1.8;
  font-size: 18px;
  text-indent: 25px;
  text-align: justify;

  @media screen and (max-width: 460px) {
    line-height: 1.4;
  }
`

export const SubTitle = styled.h2`
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
  line-height: 65px;
  @media screen and (max-width: 550px) {
    line-height: 30px;
    font-size: 22px;
  }
`

export const MainTitle = styled.div`
  width: 100%;
  font-size: 20px;
  text-align: center;
  color: #50667b;
  line-height: 40px;
  margin-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 15px;
  @media screen and (max-width: 550px) {
    line-height: 30px;
    font-size: 22px;
  }
  @media screen and (max-width: 460px) {
    margin-bottom: 0;
  }
`

export const Menu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e3e6;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

export const MenuItem = styled(Link)`
  width: 20%;
  border: none;
  background: none;
  font: inherit;
  text-decoration: none;
  font-weight: ${({ active }) => (active ? "bold" : "inherit")};
  color: inherit;
  cursor: pointer;
  :hover {
    font-weight: bold;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 24px;
    padding: 10px 0;
  }
`

export const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const Delimiter = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid rgb(225, 227, 230);
  margin-top: 60px;
  margin-bottom: 40px;

  @media screen and (max-width: 460px) {
    margin-top: 40px;
    margin-bottom: 30px;
  }
`

export const Catalog = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const ProductContainer = styled.div`
  flex: 0 0 33%;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;

  @media screen and (max-width: 678px) {
    flex-basis: 50%;
    padding: 5px;
  }

  @media screen and (max-width: 500px) {
    flex-basis: 100%;
  }
`

export const Product = styled.div`
  border: 3px solid #aebac6;
  padding: 10px;
`
